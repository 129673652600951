// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".styles__image___2QRmy{max-width:60%;display:block;margin:20px auto}.styles__uploadingImage___3yBe1{position:relative}.styles__uploadingImage___3yBe1::after{position:absolute;top:0;left:0;right:0;bottom:0;content:'';background:rgba(255,255,255,0.75);z-index:0}\n", ""]);
// Exports
exports.locals = {
	"image": "styles__image___2QRmy",
	"uploadingImage": "styles__uploadingImage___3yBe1"
};
module.exports = exports;
