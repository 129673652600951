// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".styles__p___1ogw0{padding:1rem}.styles__bold___1xV6o{font-weight:bold}.styles__title___1yUjN{margin-top:1rem;text-transform:uppercase;border-bottom:1px solid black}.styles__label___OrHvG{padding-bottom:0.25rem}\n", ""]);
// Exports
exports.locals = {
	"p": "styles__p___1ogw0",
	"bold": "styles__bold___1xV6o",
	"title": "styles__title___1yUjN",
	"label": "styles__label___OrHvG"
};
module.exports = exports;
