// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".styles__volume___3RhbW{display:flex}.styles__volume___3RhbW>*{margin-right:1rem}.styles__volume___3RhbW>*:last-child{margin-right:0}.styles__updateForm___3LURH p{font-size:0.75rem;padding:0;padding-bottom:0.5rem}.styles__updateForm___3LURH input{margin:0;margin-bottom:0.5rem}.styles__updateForm___3LURH button{margin-top:0}\n", ""]);
// Exports
exports.locals = {
	"volume": "styles__volume___3RhbW",
	"updateForm": "styles__updateForm___3LURH"
};
module.exports = exports;
