// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".styles__timer___1iIPC{position:absolute;min-width:100vw;min-height:100vh;background-size:cover;background-position:center}\n", ""]);
// Exports
exports.locals = {
	"timer": "styles__timer___1iIPC"
};
module.exports = exports;
