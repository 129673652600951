// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".styles__byJames___1a-w2{padding:0;margin:0;font-size:0.75rem;margin-top:-1rem;margin-bottom:1rem;color:#666}.styles__byJames___1a-w2 a{font-size:0.75rem;color:#00ccbc}.styles__byJames___1a-w2 a:hover{border-bottom:1px solid #00ccbc}\n", ""]);
// Exports
exports.locals = {
	"byJames": "styles__byJames___1a-w2"
};
module.exports = exports;
