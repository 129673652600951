// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".styles__textInput___1SKi2{box-sizing:border-box;border:2px solid #2a3830;padding:1rem;width:100%;background-color:transparent;color:#2a3830;margin-top:0.25rem}\n", ""]);
// Exports
exports.locals = {
	"textInput": "styles__textInput___1SKi2"
};
module.exports = exports;
